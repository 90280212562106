<template>
  <div>
    <CCard accentColor="primary">
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid" />
          {{ title }}
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow v-show="!formVisibility">
          <CCol md="12">
              <div role="group" class="form-group" v-if="user.role.superadmin == 1">
                <label>OPD</label>
                <v-select
                placeholder="Nama OPD"
                v-model="form.nama_unit_kerja"
                :options="optionsOPD"
                label="nama_opd"
                :reduce="a => a.nama_opd"
                :filterable="true"
                @search="onSearchOPD"
                ></v-select>
              </div>
            <CInput
              label="No Usulan"
              placeholder="No Usulan"
              v-model="form.no_surat"
            ></CInput>
          </CCol>
        </CRow>
        <CButtonGroup size="sm">
          <CButton color="info" v-show="!formVisibility" @click="loadData()">
            <CIcon name="cil-search" />Cari
          </CButton>
          <CButton color="warning" v-show="!formVisibility" @click="reset()">
            <CIcon name="cil-loop-circular" />Reset
          </CButton>
          <CButton
            color="success"
            v-show="!formVisibility"
            @click="openNewForm()"
          >
            <CIcon :name="icon_button" />
            {{ button_label }}
          </CButton>
        </CButtonGroup>
        <hr />
        <CCollapse :show="formVisibility">
          <form-input
            @done="resetTabel"
            @clicked="onClickChild"
            ref="formInput"
          />
        </CCollapse>
        <CDataTable
          :items="computedItems"
          :fields="fields"
          hover
          small
          border
          v-show="!formVisibility"
          :loading="loading"
        >
          <template #status="{ item }">
            <td>
              {{ item.status }}
              <div v-if="item.catatan != ''" style="font-size:11px;font-weight:bold;">
                <hr style="margin-top:10px;margin-bottom:5px;">
                {{ item.catatan }}
              </div>
            </td>
          </template>
          <template #show_details="{ item, index }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                class="ml-1 mt-1"
                @click="toggleDetails(item, false)"
                v-if="
                  item.tracking_layanan.length > 0 &&
                  ( ( item.tracking_layanan[item.tracking_layanan.length - 1].status == 3 && (item.tracking_layanan[item.tracking_layanan.length - 1].status_hasil == 0 || item.tracking_layanan[item.tracking_layanan.length - 1].status_hasil == 2) )
                  )"
                >{{ Boolean(item._toggled) ? "Tutup" : "Edit" }}</CButton
              >
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                class="ml-1 mt-1"
                @click="toggleDetails(item, true)"
                v-else
                >View</CButton
              >
              <CButton
                size="sm"
                color="danger"
                variant="outline"
                square
                class="ml-1 mt-1"
                @click="delete_item(item.id)"
                v-if="
                  item.tracking_layanan.length > 0 &&
                  ( item.tracking_layanan[item.tracking_layanan.length - 1].status <= 3 &&
                    ( item.tracking_layanan[item.tracking_layanan.length - 1].status_hasil == 0 ||
                      item.tracking_layanan[item.tracking_layanan.length - 1].status_hasil == 2 )
                  )"
                >Delete</CButton
              >
              <CButton
                color="warning"
                variant="outline"
                square
                size="sm"
                class="ml-1 mt-1"
                @click="viewTracking(item, index)"
                >Tracking</CButton
              >
            </td>
          </template>
        </CDataTable>
        <CPagination
          :activePage.sync="page"
          :pages.sync="totalPage"
          size="sm"
          align="end"
          v-show="!formVisibility"
        />
      </CCardBody>
    </CCard>
    <modal-tracking ref="modalViewTracking" />
  </div>
</template>
<script>
import formInput from "./form";
import Swal from "sweetalert2";
import VSelect from "vue-select";
import modalTracking from "../TrackingLayanan/ModalViewTracking";
import { mapGetters } from "vuex";

const fields = [
  { key: "number", label: "No" },
  { key: "no_surat", label: "No. Usulan" },
  { key: "tgl_surat", label: "Tanggal Usulan" },
  { key: "nama_unit_kerja", label: "OPD" },
  { key: "jumlah_usulan", label: "Jumlah yang Diusulkan" },
  { key: "status", label: "Status" },
  {
    key: "show_details",
    label: "Action",
    sorter: false,
    filter: false,
  },
];
export default {
  name: "UsulanPenerbitanSkPensiun",
  components: {
    formInput,
    VSelect,
    modalTracking
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
      formVisibility: false,
      loading: false,
      page: 1,
      totalPage: 0,
      optionsInstansi: [],
      optionsOPD: [],
      optionsUnitKerja: [],
      optionsJabatanUmum: [],
      form: {
        nama_unit_kerja: null,
        no_surat: null,
        jenis_layanan_general: 'pensiun'
      },
    };
  },
  watch: {
    page: function () {
      this.loadData();
    },
  },
  computed: {
    ...mapGetters("auth", { user: "user" }),
    computedItems() {
      var number = 1;
      let total_pages = 10 * (this.page - 1);
      return this.items.map((item) => {
        var status_tindakan = "";
        var status_hasil = "";
        var catatan = "";
        if (item.tracking_layanan.length > 0) {
          var lastRowLayanan = item.tracking_layanan[item.tracking_layanan.length - 1];
          if (lastRowLayanan.status == 3) {
            status_tindakan = "Verifikasi I";
            if (lastRowLayanan.status_hasil == 0) {
              status_hasil = "Proses"
            } else if (lastRowLayanan.status_hasil == 1) {
              status_hasil = "Disetujui"
            } else if (lastRowLayanan.status_hasil == 2) {
              status_hasil = "Berkas Tidak Lengkap"
            } else if (lastRowLayanan.status_hasil == 3) {
              status_hasil = "Tidak Memenuhi Syarat"
            }
          } else if (lastRowLayanan.status == 4) {
            status_tindakan = "Verifikasi II";
            if (lastRowLayanan.status_hasil == 0) {
              status_hasil = "Proses"
            } else if (lastRowLayanan.status_hasil == 1) {
              status_hasil = "Disetujui"
            } else if (lastRowLayanan.status_hasil == 2) {
              status_hasil = "Berkas Tidak Lengkap"
            } else if (lastRowLayanan.status_hasil == 3) {
              status_hasil = "Tidak Memenuhi Syarat"
            }
          } else if (lastRowLayanan.status == 5) {
            status_tindakan = "Pengesahan";
            if (lastRowLayanan.status_hasil == 0) {
              status_hasil = "Proses"
            } else if (lastRowLayanan.status_hasil == 1) {
              status_hasil = "Disetujui"
            } else if (lastRowLayanan.status_hasil == 2) {
              status_hasil = "Berkas Tidak Lengkap"
            } else if (lastRowLayanan.status_hasil == 3) {
              status_hasil = "Tidak Memenuhi Syarat"
            }
          }
          if (lastRowLayanan.status_hasil == 2) {
            catatan = 'Catatan: ' + lastRowLayanan.keterangan
          }
        }
        return {
          ...item,
          number: number++ + total_pages,
          nama_unit_kerja: item.mst_unit_kerja.nama_unit_kerja,
          jumlah_usulan: item.pelayanan_detail.length,
          status: status_hasil != '' ? status_tindakan + ' (' + status_hasil + ')' : '',
          catatan: catatan
        };
      });
    },
    title() {
      return this.formVisibility
        ? "Form Usulan Penerbitan SK Pensiun"
        : "Tabel Usulan Penerbitan SK Pensiun";
    },
    icon_button() {
      return !this.formVisibility ? "cil-plus" : "cil-arrow-left";
    },
    button_label() {
      return !this.formVisibility ? "Tambah" : "Kembali";
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    reset() {
      this.form.nama_unit_kerja = null;
      this.form.no_surat = null;
      this.page = 1;
      this.loadData();
    },
    async loadData() {
      try {
        this.loading = true;
        let data = await this.$store.dispatch("pelayanan/indexUsulanPenerbitanSkPensiun", {
          page: this.page,
          data: this.form,
        });
        this.items = data.data;
        this.totalPage = data.last_page;
      } catch (ex) {
        this.items = [];
      } finally {
        this.loading = false;
      }
    },
    delete_item(id) {
      Swal.fire({
        title: "Peringatan!",
        text: "Apa Anda yakin ingin menghapus data ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Hapus!",
      }).then(async (value) => {
        console.log(value);
        if (value.isConfirmed) {
          await this.$store.dispatch("pelayanan/destroy", id);
          this.loadData();
          Swal.fire({
            title: "Ok!",
            text: "Data berhasil terhapus!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok!",
          });
        }
      });
    },
    resetTabel() {
      this.$refs.formInput.populateInput(null);
      this.formVisibility = false;
      this.loadData();
    },
    toggleDetails(item, formDisabled) {
      this.formVisibility = true;
      this.$refs.formInput.populateInput(item, formDisabled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
    openNewForm() {
      this.formVisibility = !this.formVisibility;
      this.$refs.formInput.populateInput(null);
    },
    onClickChild(value) {
      this.formVisibility = !this.formVisibility;
      this.$refs.formInput.populateInput(null);
    },
    async onSearchOPD(search, loading) {
      loading(true);
      await this.searchOPD(search, this);
      loading(false);
    },
    searchOPD: _.debounce(async (search, vm) => {
      let options = await vm.$store.dispatch(
        "kualifikasi_jabatan_struktural/autocomplete_opd",
        search
      );
      if (search) vm.optionsOPD = options;
    }, 300),
    viewTracking(item) {
      item.modalTitle = 'Penerbitan SK Pensiun'; // please change this according to each menu
      this.$refs.modalViewTracking.toggle(item);
    },
    async printThePrintout(item) {
      // try {
      //   this.loading = true;
      //   let response = await this.$store.dispatch("pelayanan/cetakPenerbitanSkPensiun", {
      //     id: item.id
      //   });
      //   var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      //   var fileLink = document.createElement("a");
      //   fileLink.href = fileURL;
      //   fileLink.setAttribute(
      //     "download",
      //     'Pengesahan Usulan Nomor ' + item.no_surat + ' Tanggal ' + item.tgl_surat + ".pdf"
      //   );
      //   document.body.appendChild(fileLink);
      //   fileLink.click();
      // } catch (ex) {
      //   this.items = [];
      // } finally {
      //   this.loading = false;
      // }
      window.open(
        "https://api-layanan.pojokaparatur.purwakartakab.go.id" +
        // "https://dev.api-layanan.pojokaparatur.purwakartakab.go.id" +
        // "http://127.0.0.1:8001" +
          "/api/pelayanan/cetak-penerbitan-sk-pensiun?id=" +
          item.id,
        "_blank"
      );
    },
  },
};
</script>